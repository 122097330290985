import { Fragment, useCallback, useState } from "react";
import { useTranslation } from "next-i18next";
import {
  Modal,
  InfoIconFilled,
  Button,
  useSnackbarNotification,
} from "@pairtreefamily/ui";
import { TaskTitle } from "../TaskTitle";
import { TaskDescription } from "../TaskDescription";
import { ElectronicDocumentTask, Task, TaskAssignment } from "@_types/*";
import { TaskStatusElement } from "../task-status-element";
import { TaskQuestionStatus } from "../TaskQuestionStatus";
import { PandaDocIframe } from "../PandaDocEmbed";
import { RefreshTask } from "../refresh-task";
import { Result } from "@pairtreefamily/utils";
import { UseFileUpload } from "@shared/hooks";
import { Box, Typography } from "@mui/material";
import { UseProfessionalPageTools } from "@features";
import { isSessionValid } from "./isSessionValid";

export type IssuerElectronicDocumentRowProps = {
  task: ElectronicDocumentTask;
  setTaskManagerModalOpen: (open: boolean) => void;
  setCurrentTaskBeingManaged: (task: ElectronicDocumentTask) => void;
  issueDocument: (task: Task) => Promise<unknown>; //TODO there's a real type, it's just not needed at the moment
  refreshTask: UseProfessionalPageTools["refreshTask"];
  viewDocumentsLink?: string | null;
  getFileUrl?: (fileId: string) => Promise<Result<string, unknown>>;
  getFileName?: UseFileUpload["getFileName"];
  refreshElectronicDocumentSession: (
    assignment: TaskAssignment
  ) => Promise<Result<TaskAssignment, unknown>>;
};

type ModalState =
  | {
      isOpen: true;
      sessionId: string;
      title: string;
    }
  | { isOpen: false };

export function IssuerElectronicDocumentRow(
  props: IssuerElectronicDocumentRowProps
) {
  const {
    task,
    setTaskManagerModalOpen,
    setCurrentTaskBeingManaged,
    issueDocument,
    refreshTask,
    viewDocumentsLink,
    getFileUrl,
    getFileName,
    refreshElectronicDocumentSession,
  } = props;

  const { t } = useTranslation("components");

  const { status, taskAssignments } = task;
  const [modalState, setModalState] = useState<ModalState>({ isOpen: false });
  // if assignees get their own component, this should be moved as well
  const [confirmTaskReissueOpened, setConfirmTaskReissueOpened] =
    useState<Task | null>(null);
  const [isTaskAssignmentButtonLoading, setIsTaskAssignmentButtonLoading] =
    useState(false);
  const { callSnackbar } = useSnackbarNotification();

  const handleOpenPdfModal = useCallback(
    (args: { title: string; sessionId: string | null }) => {
      //TODO error if null
      if (!args.sessionId) {
        return;
      }
      setModalState({
        isOpen: true,
        title: args.title,
        sessionId: args.sessionId,
      });
    },
    []
  );

  const onTaskAssignmentButtonClick = useCallback(
    (args: { title: string; taskAssignment: TaskAssignment }) => {
      // if the session is valid (not expired) we can open the window
      if (isSessionValid(args.taskAssignment.pandadocSession)) {
        handleOpenPdfModal({
          title: args.title,
          sessionId: args.taskAssignment.pandadocSession?.sessionId ?? null,
        });
        return;
      }

      setIsTaskAssignmentButtonLoading(true);
      refreshElectronicDocumentSession(args.taskAssignment).then((res) => {
        setIsTaskAssignmentButtonLoading(false);
        if (!res.ok) {
          callSnackbar({
            message: t("issuerElectronicDocumentRow.buttons.edit"),
            type: "error",
          });
          return;
        }

        const ta = res.content;
        handleOpenPdfModal({
          title: args.title,
          sessionId: ta.pandadocSession?.sessionId ?? null,
        });
      });
    },
    [handleOpenPdfModal, refreshElectronicDocumentSession, callSnackbar, t]
  );

  // sorting by oldest expiration date first because this (issuer) modal only
  // needs an arbitrary session, not a specific one. TODO consolidate the two
  // ElectronicDocumentRows to use the same flow for rendering pandadoc session
  // modal
  const taskAssignmentWithSession = taskAssignments
    .sort(
      (a, b) =>
        (b.pandadocSession?.expiresAt.getTime() ?? 0) -
        (a.pandadocSession?.expiresAt.getTime() ?? 0)
    )
    .reduce((acc: TaskAssignment | null, cur: TaskAssignment) => {
      if (acc !== null) {
        return acc;
      }

      if (cur.pandadocSession !== null) {
        return cur;
      }

      return acc;
    }, null);

  const hasExistingSession = !!taskAssignmentWithSession;

  const hasBeenIssued =
    hasExistingSession || status == "completed" || status === "sent";

  return {
    column1: (
      <TaskTitle
        getFileName={getFileName}
        viewDocumentsLink={viewDocumentsLink}
        getFileUrl={getFileUrl}
        task={task}
        shouldDisplayViewDocumentsLink={true}
      />
    ),
    column2: <TaskDescription task={task} />,
    column3: task.question ? (
      <TaskQuestionStatus task={task} />
    ) : (
      <TaskStatusElement task={task} />
    ),
    column4: (
      <div className="align-center flex flex-col justify-center gap-4">
        {hasExistingSession ? (
          <Button
            isLoading={isTaskAssignmentButtonLoading}
            onClick={() =>
              onTaskAssignmentButtonClick({
                title: task.title,
                taskAssignment: taskAssignmentWithSession,
              })
            }
          >
            {t("issuerElectronicDocumentRow.labels.view")}
          </Button>
        ) : (
          <p>{t("buttonView.noSessionIdLabel")}</p>
        )}

        {modalState.isOpen && (
          <Modal
            title={modalState.title}
            open={modalState.isOpen}
            onClose={() => setModalState({ isOpen: false })}
          >
            <>
              <PandaDocIframe sessionUuid={modalState.sessionId} />
            </>
          </Modal>
        )}
        {task.templateUuid && !hasBeenIssued && (
          <Button
            variant="outlined"
            onClick={() => {
              issueDocument(task);
            }}
          >
            {t("issuerElectronicDocumentRow.buttons.send")}
          </Button>
        )}
        {task.templateUuid && hasBeenIssued && (
          <Button
            variant="outlined"
            onClick={() => {
              setConfirmTaskReissueOpened(task);
            }}
          >
            {t("issuerElectronicDocumentRow.buttons.reissue")}
          </Button>
        )}
        <Button
          color="error"
          variant="outlined"
          onClick={async () => {
            setTaskManagerModalOpen(true);
            setCurrentTaskBeingManaged(task);
          }}
        >
          {t("issuerElectronicDocumentRow.buttons.edit")}
        </Button>
        <RefreshTask
          task={task}
          refreshTask={refreshTask}
          expirationDate={task.expirationDate}
        />
        <Modal
          title={t(
            "issuerElectronicDocumentRow.labels.reissueDocumentModalTitle"
          )}
          open={!!confirmTaskReissueOpened}
          onClose={() => {
            setConfirmTaskReissueOpened(null);
          }}
          submitButton={{
            label: t("issuerElectronicDocumentRow.labels.proceed"),
            onClick: async () => {
              confirmTaskReissueOpened &&
                issueDocument(confirmTaskReissueOpened);
              setConfirmTaskReissueOpened(null);
              return null;
            },
          }}
        >
          <Box pt={3} sx={{ display: "flex", gap: 3 }}>
            <Box color="secondary.main">
              <InfoIconFilled />
            </Box>

            <Typography maxWidth="650px" variant="input" component="div">
              {t("issuerElectronicDocumentRow.reissueQuestion")}
            </Typography>
          </Box>
        </Modal>
      </div>
    ),
  };
}
