import { Fragment, useCallback, useState } from "react";
import { useTranslation } from "next-i18next";
import {
  Modal,
  InfoIconFilled,
  Button,
  useSnackbarNotification,
} from "@pairtreefamily/ui";
import { TaskTitle } from "../TaskTitle";
import { TaskDescription } from "../TaskDescription";
import {
  ElectronicDocumentTask,
  Task,
  TaskAssignment,
  TaskStatus,
} from "@_types/*";
import { TaskStatusElement } from "../task-status-element";
import { TaskQuestionStatus } from "../TaskQuestionStatus";
import { PandaDocIframe } from "../PandaDocEmbed";
import { appConfig } from "@lib";
import { Result } from "@pairtreefamily/utils";
import { isSessionValid } from "./isSessionValid";

export type IssueeElectronicDocumentRowProps = {
  task: ElectronicDocumentTask;
  answer: boolean | null;
  handleSetAnswer: (e: boolean | null) => void;
  editTask?: (task: Task) => void;
  onSave: () => Promise<void>;
  refreshElectronicDocumentSession: (
    assignment: TaskAssignment
  ) => Promise<Result<TaskAssignment, unknown>>;
};

type ModalState =
  | {
      isOpen: true;
      sessionId: string;
      title: string;
      assignmentStatus: TaskStatus;
    }
  | { isOpen: false };

export function IssueeElectronicDocumentRow(
  props: IssueeElectronicDocumentRowProps
) {
  const {
    task,
    answer,
    handleSetAnswer,
    editTask,
    onSave,
    refreshElectronicDocumentSession,
  } = props;
  const { taskAssignments } = task;

  const { t } = useTranslation("components");

  useState<boolean>(false);
  const [modalState, setModalState] = useState<ModalState>({ isOpen: false });
  const [isTaskAssignmentButtonLoading, setIsTaskAssignmentButtonLoading] =
    useState(false);
  const { callSnackbar } = useSnackbarNotification();

  const handleOpenPdfModal = useCallback(
    (args: { title: string; taskAssignment: TaskAssignment }) => {
      if (!args.taskAssignment.pandadocSession?.sessionId) {
        return;
      }
      setModalState({
        isOpen: true,
        title: args.title,
        sessionId: args.taskAssignment.pandadocSession.sessionId,
        assignmentStatus: args.taskAssignment.status,
      });
    },
    []
  );

  const onTaskAssignmentButtonClick = useCallback(
    (args: { title: string; taskAssignment: TaskAssignment }) => {
      // if the session is valid (not expired) we can open the window
      if (isSessionValid(args.taskAssignment.pandadocSession)) {
        handleOpenPdfModal(args);
        return;
      }

      setIsTaskAssignmentButtonLoading(true);
      refreshElectronicDocumentSession(args.taskAssignment).then((res) => {
        setIsTaskAssignmentButtonLoading(false);
        if (!res.ok) {
          callSnackbar({
            message: t("electronicDocumentRow.errors.sessionIssue"),
            type: "error",
          });
          return;
        }
        const ta = res.content;
        handleOpenPdfModal({ title: args.title, taskAssignment: ta });
      });
    },
    [handleOpenPdfModal, refreshElectronicDocumentSession, callSnackbar, t]
  );

  return {
    column1: (
      <TaskTitle task={task} answer={answer} onAnswer={handleSetAnswer} />
    ),
    column2: <TaskDescription task={task} />,
    column3: task.question ? (
      <TaskQuestionStatus task={task} isFamily />
    ) : (
      <TaskStatusElement task={task} isFamily />
    ),
    column4: (
      <>
        {task.documentIssueStatus !== "error" && (
          <div className="flex flex-col gap-4">
            {task.TaskGroup?.providerOnly && (
              <Button
                color="error"
                variant="outlined"
                onClick={() => editTask && editTask(task)}
              >
                {t("issuerElectronicDocumentRow.buttons.edit")}
              </Button>
            )}
            {taskAssignments.map((assignment, i) => {
              let label;
              let disabled = task?.isLocked;
              if (task.status === "completed") {
                if (task.answer === false) {
                  //if the question was answered as NO, then the family doesn't need to fill anything, but we still need to show the label fill out (but with the button disabled)
                  label = t("issueeElectronicDocumentRow.labels.fillOut");
                } else {
                  //if the answer is not NO, that means, true or undefined. So if its true, and the task is compled, then show as View. And if the anwer is undefined, that means that there is no question for this task
                  label = t("issueeElectronicDocumentRow.labels.view");
                  disabled = false;
                }
              } else {
                // if there's more than 1 person assigned, be specific about who should fill this out
                if (taskAssignments.length > 1) {
                  label = t("issueeElectronicDocumentRow.labels.fillOutFor", {
                    firstName: assignment.assignee.firstName,
                  });
                } else {
                  label = t(
                    `issueeElectronicDocumentRow.labels.${
                      task.status === "needsReview" ? "view" : "fillOut"
                    }`
                  );
                  disabled = task.isLocked && task.status !== "needsReview";
                }
              }

              const assigneeSessionId = assignment.pandadocSession?.sessionId;

              if (!assigneeSessionId) {
                // TODO set error state to true, then render this message if
                // the error state is true. This is blocked by this not being
                // it's own component - an easy refactor, but out of scope
                // for what I'm doing right now.
                return null;
              }
              //since the view button opens the same document
              // when is 2 signers and
              // the document is complete
              // we are displaying the button only for the first assignee
              if (i > 0 && task.status === "completed") {
                return null;
              }
              return (
                <Fragment key={assignment.id}>
                  <Button
                    variant="outlined"
                    isLoading={isTaskAssignmentButtonLoading}
                    onClick={() => {
                      onTaskAssignmentButtonClick({
                        title: task.title,
                        taskAssignment: assignment,
                      });
                    }}
                    disabled={false}
                  >
                    {label}
                  </Button>
                </Fragment>
              );
            })}
          </div>
        )}
        {modalState.isOpen && (
          <Modal
            title={modalState.title}
            // TODO componentize assignment render and include modal/state there
            open={modalState.isOpen}
            submitButton={
              modalState.assignmentStatus !== "completed"
                ? {
                    label: t("issueeElectronicDocumentRow.labels.saveProgress"),
                    onClick: async () => {
                      await onSave();
                      setModalState({ isOpen: false });
                    },
                  }
                : undefined
            }
            onClose={() => {
              setModalState({ isOpen: false });
            }}
          >
            <>
              <PandaDocIframe sessionUuid={modalState.sessionId} />
              {modalState.assignmentStatus === "completed" && (
                <div className="flex flex-col content-center">
                  <p className="my-8">
                    {task?.taskAssignments.some(
                      (item) => item.status !== "completed"
                    )
                      ? t(
                          "issueeElectronicDocumentRow.documentStatus.yourPortionCompleted"
                        )
                      : t(
                          "issueeElectronicDocumentRow.documentStatus.allCompleted"
                        )}
                  </p>
                  <Button
                    onClick={() => {
                      setModalState({ isOpen: false });
                    }}
                  >
                    {t("issueeElectronicDocumentRow.closeButton")}
                  </Button>
                </div>
              )}
            </>
          </Modal>
        )}
        {task.documentIssueStatus === "error" && (
          <div>
            {t("issueeElectronicDocumentRow.errors.documentIssue")}
            <a href={`mailto:${appConfig.companyContactEmail}`}>
              {appConfig.companyContactEmail}
            </a>
          </div>
        )}
      </>
    ),
  };
}
